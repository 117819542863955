import React from 'react';
import './hero.scss'
// import { motion } from 'framer-motion'

function hero(props) {
    return (
        <div className="hero">
            <div className="hero-content">
                <img
                    src={props.img} alt="" />
                <div
                    className="overlay">
                    <h1>{props.title}</h1>
                </div>
            </div>
        </div>
    );
}

export default hero;