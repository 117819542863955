import React, { useEffect } from 'react';
import Hero from '../../components/Hero/hero'
import header from '../../assets/services-header.png'
import './services.scss'
import serviceImg from '../../assets/paxoil-services-pipe-wrapping.png'
import Img from '../../assets/top.png'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

function Services() {
    useEffect(() => {
        document.title = "Services | Paxoil"
    })
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const data = [
        {
            title: "Construction & Civil Works",
            desc: ["Building Constructions", "Prefab/Office Accommodation", "Culverts and Pavements"]
        },
        {
            title: "Steel Fabrications/Installations",
            desc: ["Steel Structures Fabrication & Installation", "Steel Pipes Fabrication & Installation", "Steel Pipe Wrappings/Repairs"]
        },
        {
            title: "Glassfiber Reinforced Epoxy Piping Systems (GRE) & None Metallic Piping Systems",
            desc: ["GRE Pipes Fab/Installation/Repairs", "GRE Handrails, Grating, Fabrication/Installation/Repairs", "GRE Tanks Installation/Repairs", "HDPE, PVDF, PPR, PVC, UPVC Pipes Fabrication/Installation/Repairs"]
        },
        {
            title: "Corrosion Control",
            desc: ["Blasting & Painting", "Cathodic Protection", "Epoxy Coating"]
        },
        {
            title: "Maintenance & Project Support",
            desc: ["Scaffolding", "Rigging", "Rope Access", "Safety Equipment Supplies/Installations"]
        },
        {
            title: "Electrical & Mechanical Works",
            desc: null
        },
        {
            title: "Manpower Supply & Management",
            desc: null
        },
        {
            title: "Procurement",
            desc: null
        },
    ]
    const services = data.map((service, index) => {
        return (
            <Accordion key={index} className="list">
                <AccordionSummary
                    expandIcon={service.desc === null ? "" : <AddIcon className="icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className="title">{service.title}</Typography>
                </AccordionSummary>
                {
                    service.desc === null ? "" : <AccordionDetails>
                        {/* <Typography className="desc"> */}
                        <ul className="desc">
                            {
                                Object.keys(service.desc).map(function (key) {
                                    return <li key={key} >{service.desc[key]}</li>
                                })
                            }
                        </ul>
                        {/* </Typography> */}
                    </AccordionDetails>
                }
            </Accordion>
            // <div key={index} className="list">
            //     <div className="title">{service}</div>
            //     <div className="line"></div>
            // </div>
        )
    })
    return (
        <div>
            <Hero img={header} title="Our Services" />
            <div className="services">
                <div className="services-container">
                    <div className="right">
                        <div className="weird">
                            <div className="title">It’s not just what we say,
                                it’s our expertise</div>
                            <div>
                                Our services are tailored to meet the needs of our clients both onshore and offshore with international standards and high level of clients satisfaction. We have the capacity to deliver in all our field of services end to end sticking to budget, safety and quality not compromised.
                            </div>
                            <div>
                                We have a track record of fielding only competent engineers who are certified by relevant bodies in their various departments with years of experience which has reflected positively in all our projects.
                            </div>
                        </div>
                        <div className="container">
                            {services}
                        </div>
                    </div>
                    <div className="left">
                        <img src={serviceImg} alt="service Img" />
                        <div className="overlay-bottom"></div>
                        <img className="overlay-top" src={Img} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;