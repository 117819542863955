import React, { useEffect } from 'react';
import Hero from '../../components/Hero/hero'
import header from '../../assets/about-header.png'
import './about.scss'
// import aboutImage from '../../assets/about-img.png'
import vision from '../../assets/vision.png'
import mission from '../../assets/mission.png'
// import watermark from '../../assets/watermark.png'
import { motion } from 'framer-motion'

function About() {
    useEffect(() => {
        document.title = "About us | Paxoil"
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])    
    const data = [
        {
            title: "Integrity",
            desc: "We stand by our words through unbroken commitment to our shared values."
        },
        {
            title: "Teamwork",
            desc: "We do it as a team, that is why we don’t stop until results are achieved. United we stand."
        },
        {
            title: "Capacity Building",
            desc: "We build human capacity by providing an enabling work environment that promote and inspires for greatness."
        },
        {
            title: "Collaboration",
            desc: "We work closely with our clients and build relationship to better understand their needs."
        },
        {
            title: "Responsibility",
            desc: "Responsibility means a great deal to us as it strengthens our commitment will deliver value to clients, shareholders, and our host community."
        },
        {
            title: "Professionalism ",
            desc: "We are committed to continuous professional growth of our teams, honouring our commitment to quality in our operations."
        },
    ]
    const values = data.map((value, index) => {
        return (
            <motion.div whileHover={{
                scale: 1.1,
                transition: { duration: 1 },
            }}
                key={index} className="card">
                {/* <div>
                    <img src={watermark} alt="watermark" />
                </div> */}
                <div className="title"> {value.title}</div>
                <div className="desc">
                    {value.desc}
                </div>
            </motion.div>
        )
    })
    return (
        <div className="about">
            <Hero img={header} title="About Paxoil" />
            <div className="about-content">
                <motion.div className="flex">
                    <div className="left">
                        <div className="title">We’re a servicing engineering company pioneering innovative solutions across board</div>
                    </div>
                    <div className="left">
                        <div className="text">
                            Paxoil is an indigenous Nigerian servicing company focused majorly on providing a full range of onshore and offshore services in the oil & gas industry, contributing greatly in the huge growth of the energy sector in Nigeria. We have built a reputation, with an established presence  in the field of Steel Fabrications, Glassfibre Reinforced Piping System (GRE), Construction, Scaffolding, Rigging, Painting and Sand Blasting, Glassfibre Handrail and Grating, Pipe Wrapping, Electrical & Mechanical Works, Safety Equipment Supplies, Maintenance and Project Support.
                        </div>
                        <div className="text">
                            Our goal as a servicing company is to consistently create values to our clients, be a force and significant player across our entire fields of service delivery with strict adherence to global standards that eliminate compromise.
                        </div>
                    </div>
                </motion.div>

                <div className="vision">
                    <div className="text">
                        <div className="title">Our Vision</div>
                        <div className="desc">To build world-class service company through continuous focus on quality, safety and clients need.</div>
                    </div>
                    <div className="img">
                        <img src={vision} alt="" />
                    </div>
                </div>
                <div className="vision">
                    <div className="text">
                        <div className="title">Our Mission</div>
                        <div className="desc">
                            To create a unique experience in all our service delivery and engagements.
                        </div>
                    </div>
                    <div className="img">
                        <img className='mission' src={mission} alt="" />
                    </div>
                </div>
                {/* <div className="flex2 my-2">
                    <div className="flex">
                        <img alt="vision" src={vision} />
                        <div className="card">
                            <div className="title">Vision. </div>
                            <div className="description">To build world-class service company through continuous focus on quality, safety and clients need.</div>
                        </div>
                    </div>
                    <div className="flex">
                        <img alt="mission" src={mission} />
                        <div className="card">
                            <div className="title">Mission. </div>
                            <div className="description">To create a unique experience in all our service delivery and engagements.</div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="values">
                <div className="heading">Our Values</div>
                <div className="container">
                    {values}
                </div>
            </div>
        </div>
    );
};

export default About;