import React, { useEffect } from 'react';
import Hero from '../../components/Hero/hero'
import header from '../../assets/paxoil-hsp-header-img.png'
import './health-safety.scss'
import img1 from "../../assets/paxoil-hs-policy-2.png"
// import img2 from "../../assets/paxoil-hs-policy-1.png"

function HealthSafety() {
    useEffect(() => {
        document.title = "Health and Safety Policy | Paxoil"
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])    
    return (
        <div>
            <Hero img={header} title="Health & Safety Policy" />
            <div className="safety">
                <div className="second">
                    <img src={img1} alt="overlay" />
                </div>
                <div className="first">
                    <div className="title">Safety is the First Step</div>
                    <div>We are committed to maintaining highest level of health and safety standards in all our operations at all times knowing that our success is directly linked to the safety of our employees and the public. We continuously and consistently review and improve the working conditions of our employees and the environment of our operations.
                    </div>
                    <div>We comply to both local and international health and safety standards and improve upon all our safety practices and processes that clearly communicate safety expectations.</div>
                    <div>In compliance with our health and safety measures, we train and re-train our employees and mandate them to always be safety conscious whether under supervision or not.</div>
                    <div>As the saying goes, safety is key.</div>
                </div>
            </div>
        </div>
    );
}

export default HealthSafety;