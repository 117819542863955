import React, { useEffect } from 'react';
import Hero from '../../components/Hero/hero'
import header from '../../assets/csr-header.png'
import './csr.scss';
import csr1 from '../../assets/paxoil-csr-1.png'
import csr2 from '../../assets/paxoil-csr-2.png'
// import { motion } from 'framer-motion'


function Csr() {
    useEffect(() => {
        document.title = "Corporate Social Responsibility | Paxoil"
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])    
    return (
        <div>
            <Hero img={header} title="Corporate Social
                Responsibility" />
            <div className="csr">
                <div className="container">
                    <div className="flex">
                        <img
                            src={csr2} alt="csr" />
                        <div className="csr-container">
                            <div className="title">We are Responsible</div>
                            <div>
                                At Paxoil, we are a committed and relevant member of our host communities and her environ. As a socially responsible servicing company, we have taken Corporate Social Responsibility as an integral part of who we are.
                            </div>
                            <div>Our goal is to create an enduring relationships that will add  value to the host communities in which we operate.</div>
                            <div>Our Corporate Social Responsibility focuses on Arts and Culture, Education, Community Development and the Natural Environment.</div>
                            <div>We are committed to the aforementioned areas of focus of our Corporate Social Responsibility (CSR) knowing that their societal needs cannot be overlooked.</div>
                        </div>
                    </div>
                    <div className="single">
                        <img
                            src={csr1} alt="csr  " />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Csr;