import React, { useState } from 'react';
import logo from '../../assets/PAXOIL-LOGO.png'
import nav from '../../assets/nav.png'
import close from '../../assets/close.png'
import arrow from '../../assets/Arrow5.png'
// import logoBlue from "../../assets/blue-logo.png"

import { motion } from 'framer-motion'
import './nav.scss'
import { Link } from 'react-router-dom'

function HeaderNav() {
    const [isOpen, setIsOpen] = useState(false);
    const menuVariants = {
        opened: {
            top: 0
        },
        closed: {
            top: "-100vh"
        }
    };
    return (
        <motion.div
            initial={{ opacity: 0, y: -180 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                ease: "easeInOut",
                dealy: .8
            }}
            className="Header">
            <div className="webNav">
                <div>
                    <Link to="/">
                        <img src={logo} alt="logo img" />
                    </Link>
                </div>

                <ul>
                    <li>
                        <Link to="/about">about</Link>
                    </li>
                    <li>
                        <Link to="/services">services</Link>
                    </li>
                    <li>
                        <Link to="/health-safety">health & safety</Link>
                    </li>
                    <li>
                        <Link to="/csr">csr</Link>
                    </li>
                    <li>
                        <Link to="/contact">contact</Link>
                    </li>
                </ul>
            </div>
            <div className="mobileNav">
                <div className="toggler">
                    <Link to="/">
                        <img id="logo" src={logo} alt="logo" />
                    </Link>
                    <img onClick={() => setIsOpen(state => !state)} id="nav" src={nav} alt="nav" />
                </div>
                <div >
                    <motion.ul initial={false} variants={menuVariants} animate={isOpen ? "opened" : "closed"}>
                        <div className="mobile-close">
                            {/* <Link to="/">
                                <img id="logo2" src={logoBlue} alt="logo" />
                            </Link> */}
                            <img id="close" onClick={() => setIsOpen(state => !state)} src={close} alt="Close" />
                        </div>
                        <li onClick={() => setIsOpen(state => !state)}>
                            <Link to="/about">about</Link>
                        </li>
                        {/* <hr /> */}
                        <li onClick={() => setIsOpen(state => !state)}>
                            <Link to="/services">services</Link>
                        </li>
                        {/* <hr /> */}
                        <li onClick={() => setIsOpen(state => !state)}>
                            <Link to="/health-safety">health & safety</Link>
                        </li>
                        {/* <hr /> */}
                        <li onClick={() => setIsOpen(state => !state)}>
                            <Link to="/csr">csr</Link>
                        </li>
                        {/* <hr /> */}
                        <li onClick={() => setIsOpen(state => !state)}>
                            <Link to="/contact">contact</Link>
                        </li>
                        <img className="my-2" src={arrow} alt="arrow" />
                        <li>
                            +234 803 706 8309
                        </li>
                        <li>
                            info@paxoilandgas.com
                        </li>
                    </motion.ul>
                </div>
            </div>
        </motion.div>
    );
}

export default HeaderNav;